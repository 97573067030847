<template>
  <footer>
    <div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <b>horizon architecture</b>
            <span>|</span>
            {{ agence.numero }}
            {{ agence.rue }}
            {{ agence.codePostal }}
            {{ agence.ville }}
            <span>|</span>
            {{ telephoneFilter }}
            <span>|</span>
            {{ agence.email }}
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import store from '@/store';

import { Agence } from '@/model/Agence';

export default class Footer extends Vue {

  get agence() {
    return store.state.agence as Agence;
  }

  get telephoneFilter() {
    return this.agence.telephone.split('-').join(' ');
  }

}
</script>

<style scoped lang="scss">
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  max-height: 100px;
  padding-top: 1px;
  z-index: 999;
}

footer > div {
  border-top: solid 1px #222222;
  padding: 20px 0;
}

b {
  font-size: 1.3em;
}

img {
  margin-right: 15px;
}

span {
  margin: 0 5px;
}
</style>
