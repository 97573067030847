import { AbstractBuilder } from '@/model/builder/_Builder';
import { Reference, ReferenceCollection } from '@/model/Reference';
import { ReferenceDtoInterface } from '@/interface/dto/ReferenceDtoInterface';
import { LegendeBuilder } from '@/model/builder/LegendeBuilder';
import { LegendeDtoInterface } from '@/interface/dto/LegendeDtoInterface';
import { CollectionDtoInterface } from '@/interface/dto/CollectionDtoInterface';
import { ReferenceImagesBuilder } from '@/model/builder/ReferenceImagesBuilder';
import { ReferenceImagesDtoInterface } from '@/interface/dto/ReferenceImagesDtoInterface';

export class ReferenceBuilder extends AbstractBuilder {

  static build(data: ReferenceDtoInterface): Reference {
    const model: Reference = new Reference();

    if (typeof data.id === 'number') {
      model.id = data.id;
    }

    if (typeof data.titre === 'string') {
      model.titre = data.titre;
    }

    if (typeof data.localisation === 'string') {
      model.localisation = data.localisation;
    }

    if (typeof data.etat === 'string') {
      model.etat = data.etat;
    }

    if (typeof data.description === 'string') {
      model.description = data.description;
    }

    if (typeof data.legendes === 'object') {
      model.legendes = LegendeBuilder.buildCollection({
        total: null,
        result: data.legendes as LegendeDtoInterface[]
      }).legendes;
    }

    if (typeof data.images === 'object') {
      model.setImages(ReferenceImagesBuilder.buildCollection(data.images as ReferenceImagesDtoInterface[]));
    }

    return model;
  }

  static buildCollection(data: CollectionDtoInterface): ReferenceCollection {
    const collection: ReferenceCollection = new ReferenceCollection();
    const result = data.result as ReferenceDtoInterface[];

    Object.values(result).forEach((element: ReferenceDtoInterface) => {
      collection.references.push(this.build(element));
    });

    if (typeof data.total === 'number') {
      collection.total = data.total;
    }

    return collection;
  }

}
