import { ReferenceBuilder } from '@/model/builder/ReferenceBuilder';
import { ReferenceDtoInterface } from '@/interface/dto/ReferenceDtoInterface';
import { Reference, ReferenceCollection } from '@/model/Reference';
import { AbstractApiParam, ApiService } from '@/service/ApiService';
import { CollectionDtoInterface } from '@/interface/dto/CollectionDtoInterface';
import { ApiParamInterface } from '@/interface/service/ApiParamInterface';

export class ReferenceRepository {

  public static get(dto?: ReferenceApiDto) {
    return ApiService.get('projet', dto)
      .then((data) => {
        return Promise.resolve().then((): ReferenceCollection => {
          return ReferenceBuilder.buildCollection(data as CollectionDtoInterface);
        });
      });
  }

  public static find(id: number, dto?: ReferenceApiDto) {
    return ApiService.get('projet/' + id, dto)
      .then((data) => {
        return Promise.resolve().then((): Reference => {
          return ReferenceBuilder.build(data as ReferenceDtoInterface);
        });
      });
  }

  public static save(id?: number, dto?: ApiParamInterface) {
    return id === undefined
      ? ReferenceRepository.add(dto)
      : ReferenceRepository.update(id, dto);
  }

  public static add(dto?: ApiParamInterface) {
    return ApiService.post('projet', dto)
      .then((data) => {
        return Promise.resolve().then((): Reference => {
          return ReferenceBuilder.build(data as ReferenceDtoInterface);
        });
      });
  }

  public static update(id: number, dto?: ApiParamInterface) {
    return ApiService.put('projet/' + id, dto)
      .then((data) => {
        return Promise.resolve().then((): Reference => {
          return ReferenceBuilder.build(data as ReferenceDtoInterface);
        });
      });
  }

  public static delete(id: number) {
    return ApiService.delete('projet/' + id);
  }

}

export class ReferenceApiDto extends AbstractApiParam {}
