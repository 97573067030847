import { AbstractModel } from '@/model/_Model';

export class Agence extends AbstractModel {

  private _telephone: string;
  private _email: string;
  private _numero: number;
  private _rue: string;
  private _codePostal: string;
  private _ville: string;

  constructor() {
    super();

    this._telephone = '';
    this._email = '';
    this._numero = 0;
    this._rue = '';
    this._codePostal = '';
    this._ville = '';
  }

  get telephone(): string {
    return this._telephone;
  }

  set telephone(value: string) {
    this._telephone = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get numero(): number {
    return this._numero;
  }

  set numero(value: number) {
    this._numero = value;
  }

  get rue(): string {
    return this._rue;
  }

  set rue(value: string) {
    this._rue = value;
  }

  get codePostal(): string {
    return this._codePostal;
  }

  set codePostal(value: string) {
    this._codePostal = value;
  }

  get ville(): string {
    return this._ville;
  }

  set ville(value: string) {
    this._ville = value;
  }

}
