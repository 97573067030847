import { Reference, ReferenceCollection } from '@/model/Reference';
import { ReferenceRepository } from '@/repository/ReferenceRepository';

interface State {
  references: Reference[];
};

interface Context {
  commit(arg0: string, arg1: unknown): void;
};

export default {
  namespaced: true,
  state: () => ({
    references: []
  }),
  mutations: {
    references: (state: State, references: Reference[]) => {
      state.references = references;
    }
  },
  actions: {
    load(context: Context) {
      ReferenceRepository.get().then((collection: ReferenceCollection) => {
        context.commit('references', collection.references);
      });
    }
  }
};
