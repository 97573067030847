// @ts-ignore
import config from '../../env.json';

import { verbeHttpEnum } from '@/enum/VerbeHttpEnum';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { ApiParamInterface } from '@/interface/service/ApiParamInterface';

export class ApiService {

  static get(url: string, param?: ApiParamInterface) {
    return this._send(verbeHttpEnum.GET, url, param);
  }

  static post(url: string, param?: ApiParamInterface) {
    return this._send(verbeHttpEnum.POST, url, param);
  }

  static put(url: string, param?: ApiParamInterface) {
    return this._send(verbeHttpEnum.PUT, url, param);
  }

  static delete(url: string) {
    return this._send(verbeHttpEnum.DELETE, url);
  }

  static file(url: string, param?: ApiParamInterface) {
    return this._send(verbeHttpEnum.FILE, url, param);
  }

  private static _generateUrl(url: string, param?: ApiParamInterface) {
    let _param = '';

    if (typeof param !== 'undefined') {
      for (const [key, value] of Object.entries(param.param())) {
        _param = this._generateGetParam(_param, key, value);
      }
    }

    return config.api.uri + url + _param;
  }

  private static _generateGetParam(param: string, key: string, value: unknown): string {

    if (typeof value !== 'undefined' && value !== null) {
      if (typeof value === 'object') {
        for (const value2 of Object.values(value as object)) {
          param = this._generateGetParam(param, `${key}[]`, value2);
        }
      } else {
        param += (param === '' ? '?' : '&') + `${key}=${value}`;
      }
    }

    return param;
  }

  private static _send(method: string, url: string, param?: ApiParamInterface): Promise<unknown> {
    const axiosHeaders = {
      accept: 'application/json',
      'content-type': method === verbeHttpEnum.FILE ? 'multipart/form-data' : 'application/json; charset=utf-8'
    };

    const myAxios: AxiosInstance = axios.create({ headers: axiosHeaders });

    let response;

    switch (method) {
      case verbeHttpEnum.PUT:
        response = myAxios.put(
          ApiService._generateUrl(url),
          typeof param !== 'undefined' ? param.param() : null,
          { withCredentials: true }
        );
        break;

      case verbeHttpEnum.POST:
      case verbeHttpEnum.FILE:
        response = myAxios.post(
          ApiService._generateUrl(url),
          typeof param !== 'undefined' ? param.param() : null,
          { withCredentials: true }
        );
        break;

      case verbeHttpEnum.DELETE:
        response = myAxios.delete(
          ApiService._generateUrl(url),
          { withCredentials: true }
        );
        break;

      case verbeHttpEnum.GET:
      default:
        response = myAxios.get(
          ApiService._generateUrl(url, param),
          { withCredentials: true }
        );
        break;
    }

    return response
      .then((_response: AxiosResponse): Promise<unknown> => this._response(_response))
      .catch((_error: AxiosError): Promise<unknown> => this._error(_error));
  }

  private static _response(response: AxiosResponse): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (response.status === 204 || typeof response.data === 'object') {
        const data: AxiosResponseData = response.data;

        if (typeof data.data !== 'undefined') {
          resolve(data.data);
        } else {
          resolve();
        }
      } else {
        reject(new Error('Bad response'));
      }
    });
  }

  private static _error(error: AxiosError): Promise<unknown> {
    let message = 'An error occurred';

    if (
      typeof error.response === 'object' &&
      typeof error.response.data === 'object' &&
      typeof error.response.data.error === 'object' &&
      typeof error.response.data.error.description === 'string'
    ) {
      message = error.response.data.error.description;
    }

    return new Promise((resolve, reject) => {
      reject(new Error(message));
    });
  }

}

export abstract class AbstractApiParam implements ApiParamInterface {

  with: string[] = [];
  limit: number | null = null;
  offset: number | null = null;

  param(): {} {
    return {
      with: this.with,
      limit: this.limit,
      offset: this.offset
    };
  }

}

interface AxiosResponseData {
  statusCode: number;
  data?: object;
  error?: {
    type?: string;
    description?: string;
  };
}
