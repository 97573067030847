import { reactive } from 'vue';
import { createStore } from 'vuex';

import agenceStore from '@/store/agence';
import referencesModule from '@/store/references';

const store = createStore({
  state: reactive({
    agence: agenceStore,
    minified: false
  }),
  mutations: {
    toggleMinified: (state) => {
      state.minified = !state.minified;
    }
  },
  modules: {
    reference: referencesModule
  }
});

export default store;
