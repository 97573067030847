<template>
  <div class="row">
    <div class="col-12 col-sm-7 col-md-8">
      <img src="@/assets/img/map.jpg" class="img-fluid pointer" v-on:click="googleMap()" />
    </div>
    <div class="col-12 col-sm-5 col-md-4" style="text-align: right">
      <p>
        <b>horizon</b> architecture<br />
      </p>
      <br />
      <p>
        {{ agence.numero }} {{ agence.rue }}<br/>
        {{ agence.codePostal }} {{ agence.ville }}<br/><br/>
        Tel <a :href="'tel:' + agence.telephone">{{ agence.telephone }}</a><br/>
        <a :href="'mailto:' + agence.email">{{ agence.email }}</a><br/>
      </p>
      <br />
      <p>
        société inscrite à l'ordre des architecte : <a href="https://www.architectes.org/">S19277</a><br/>
      </p>
      <br /><br />
      <p>
        <b>Guillaume Fillon</b><br/>
        Architecte HMONP<br/>
        Inscrit à l'ordre des architectes : <a href="https://www.architectes.org/">084481</a><br/>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import store from '@/store';

import { Agence } from '@/model/Agence';

export default class Contact extends Vue {

  get agence() {
    return store.state.agence as Agence;
  }

  public googleMap() {
    window.open(
      'https://www.google.com/maps/place/11+Rue+F%C3%A9libien,+28000+Chartres/@48.447964,1.4815571,16.75z/data=!4m5!3m4!1s0x47e40c4749554755:0xafb9a25fe2a4647a!8m2!3d48.44849!4d1.482679',
      '_blank'
    );
  }

}
</script>

<style scoped lang="scss">
.row {
  font-size: 0.9em;
}

input, textarea {
  border: solid 1px #000000;
}

input.input, textarea.input {
  width: 100%;
  margin-bottom: 3px;
  padding: 2px 5px;
}

input.error, textarea.error {
  border-color: red;
}

textarea {
  height: 81px;
}

input[type="submit"] {
  background-color: #bada55;
}

span.error {
  color: red;
}

p {
  margin: 0;
}
</style>
