import { AbstractModel, AbstractModelCollection } from '@/model/_Model';

export class LegendeRef extends AbstractModel {

  private _id?: number;
  private _label?: string;

  get id(): number | undefined {
    return this._id;
  }

  set id(value: number | undefined) {
    this._id = value;
  }

  get label(): string | undefined {
    return this._label;
  }

  set label(value: string | undefined) {
    this._label = value;
  }

}

export class LegendeRefCollection extends AbstractModelCollection {

  private _legendes: LegendeRef[] = [];

  get legendes(): LegendeRef[] {
    return this._legendes;
  }

  set legendes(value: LegendeRef[]) {
    this._legendes = value;
  }

}
