
import { Options, Vue } from 'vue-class-component';

import Miniature from '@/components/reference/Miniature.vue';
import Loading from '@/components/Loading.vue';
import { ReferenceApiDto, ReferenceRepository } from '@/repository/ReferenceRepository';
import { Reference, ReferenceCollection } from '@/model/Reference';

@Options({
  components: {
    Miniature,
    Loading
  }
})
export default class List extends Vue {

  readonly limit = 12;
  loading = false;
  total = -1;
  page = 0;
  references: Reference[] = [];

  created() {
    this.find();
  }

  more() {
    this.page += 1;
    this.find();
  }

  public get hasMore() {
    return this.references.length < this.total;
  }

  private find() {
    const dto: ReferenceApiDto = new ReferenceApiDto();

    dto.limit = this.limit;
    dto.offset = this.limit * this.page;
    dto.with.push('images.image');

    this.loading = true;

    ReferenceRepository.get(dto).then((collection: ReferenceCollection) => {
      this.total = collection.total;
      this.references = this.references.concat(collection.references);
    }).finally(() => { this.loading = false; });
  }

}
