<template>
  <div class="row">
    <div v-for="reference in references" :key="reference.id" class="col-6 col-sm-4 col-md-3" style="padding: 15px;">
      <Miniature :reference="reference" />
    </div>
  </div>
  <Loading v-if="loading" />
  <div class="row" v-else-if="hasMore">
    <div class="col-12 text-center">
      <button class="btn btn-sm btn-dark" @click="more()">voir plus</button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import Miniature from '@/components/reference/Miniature.vue';
import Loading from '@/components/Loading.vue';
import { ReferenceApiDto, ReferenceRepository } from '@/repository/ReferenceRepository';
import { Reference, ReferenceCollection } from '@/model/Reference';

@Options({
  components: {
    Miniature,
    Loading
  }
})
export default class List extends Vue {

  readonly limit = 12;
  loading = false;
  total = -1;
  page = 0;
  references: Reference[] = [];

  created() {
    this.find();
  }

  more() {
    this.page += 1;
    this.find();
  }

  public get hasMore() {
    return this.references.length < this.total;
  }

  private find() {
    const dto: ReferenceApiDto = new ReferenceApiDto();

    dto.limit = this.limit;
    dto.offset = this.limit * this.page;
    dto.with.push('images.image');

    this.loading = true;

    ReferenceRepository.get(dto).then((collection: ReferenceCollection) => {
      this.total = collection.total;
      this.references = this.references.concat(collection.references);
    }).finally(() => { this.loading = false; });
  }

}
</script>

<style scoped lang="scss">
</style>
