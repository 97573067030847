
import { Vue } from 'vue-class-component';
import store from '@/store';

import { Agence } from '@/model/Agence';

export default class Footer extends Vue {

  get agence() {
    return store.state.agence as Agence;
  }

  get telephoneFilter() {
    return this.agence.telephone.split('-').join(' ');
  }

}
