import { Agence } from '@/model/Agence';

const agence: Agence = new Agence();

agence.numero = 11;
agence.rue = 'rue Félibien';
agence.codePostal = '28000';
agence.ville = 'Chartres';
agence.telephone = '09-87-76-54-55';
agence.email = 'contact@horizon-architecture.fr';

export default agence;
